import React from "react"
import { Link } from "react-scroll"
import logo from "../assets/logo.png"

export default function ButtonLogoNavLink({
  idSection,
  setActive,
  classNameImage,
  setDisplaySim,
}) {
  return (
    <Link
      to={idSection}
      spy={true}
      smooth={true}
      offset={-150}
      duration={300}
      delay={100}
      className="cursor-pointer"
      onClick={() => {
        document.title = `PORTAYLI - Accueil`
        setActive(0)
        setDisplaySim(false)
      }}
    >
      <img src={logo} alt="Logo PORTAYLI" className={classNameImage} />
    </Link>
  )
}

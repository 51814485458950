import React from "react"
import { CloseIconModal } from "./Icons"

export function Modal({ children, className }) {
  return (
    <div className="absolute left-0 top-0 z-[4] flex min-h-full w-full items-center justify-center">
      <div
        className={`bg-shape my-4 flex flex-col rounded-lg p-4 ${className}`}
      >
        {children}
      </div>
    </div>
  )
}

export function ModalHeader({ children, className, setOpen }) {
  return (
    <>
      <div
        className={`border-secondaryText flex w-full items-center justify-between ${className}`}
      >
        <div className="text-xl font-semibold text-primary">{children}</div>
        <button
          className="h-4 w-4 rounded-full"
          onClick={() => {
            if (setOpen) {
              setOpen(false)
            }
          }}
        >
          <CloseIconModal className="h-full w-full fill-gray" />
        </button>
      </div>
    </>
  )
}

export function ModalBody({ children, className }) {
  return <div className={`${className}`}>{children}</div>
}

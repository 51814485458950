export function isValidName(name) {
  if (!name.trim().length) return "Champ obligatoire"
  return ""
}

export function isValidEmail(email) {
  if (!email.trim().length) return "Champ obligatoire"
  const emailFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi

  if (!emailFormat.test(email)) return "Format d'email invalide"
  return ""
}

export function isValidPhone(phoneNumber) {
  if (!phoneNumber.trim().length) return "Champ obligatoire"
  if (!/^\d+$/.test(phoneNumber) || phoneNumber.length < 10)
    return "Numéro de téléphone invalide"
  return ""
}

export function isEmpty(value) {
  if (!value.trim().length) throw new Error("Champ obligatoire")
}

export function isNoChoose(value) {
  if (value === "Sélectionner une valeur") throw new Error("Champ obligatoire")
}

export function isGreater(value, max) {
  if (max && Number(value) < max)
    throw new Error(`La valeur minimale est ${max}`)
}

export function isLessThanZero(value) {
  if (Number(value) < 0)
    throw new Error("La valeur ne peut pas être inférieure à 0")
}

export function separateWithSpace(number) {
  const formatter = new Intl.NumberFormat("fr-FR")
  const formattedNumber = formatter.format(number)
  return formattedNumber
}

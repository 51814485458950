import React from "react"
import { FormContext } from "./Simulateur"
import { ExclamationIcon } from "./Icons"

export default function InputWithLabel({
  index,
  label,
  type,
  name,
  placeholder,
  symbol,
  required,
  min,
}) {
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = React.useState("")
  const FormContextContent = React.useContext(FormContext)

  const inputData = {
    setErrorMessage: setErrorMessage,
    name: name,
    value: value,
    required: required,
  }
  if (
    FormContextContent.find((element, indexElement) => indexElement === index)
  )
    FormContextContent[index] = inputData
  else FormContextContent.push(inputData)
  return (
    <div className="relative flex w-full flex-col gap-1.5">
      <label className="text-sm text-primary">{label}</label>
      <div
        className={`flex items-center rounded-md border ${
          errorMessage.length ? "border-redError " : "border-transparent"
        } bg-darkGrayLight ${symbol ? "pr-4" : ""}`}
      >
        <input
          type={type}
          value={value}
          className={`w-full bg-transparent p-4 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none`}
          min={min ? min : 0}
          onChange={(e) => {
            setValue(e.target.value)
            setErrorMessage("")
          }}
          placeholder={`Entrez ${placeholder}`}
        />
        {symbol ? (
          <span className="text-sm text-[#B2B2B1]">{symbol}</span>
        ) : null}
      </div>
      {errorMessage.length ? (
        <div className="absolute bottom-[-1.6rem] flex items-center gap-1.5 text-sm font-medium text-redError">
          <ExclamationIcon className="h-4 w-4 fill-redError" />
          <span>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  )
}

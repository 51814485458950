import React from "react"
import Hero from "./Hero"
import Offers from "./Offers"
import Advantages from "./Advantages"
import Tarif from "./Tarif"
import WhyPortAyli from "./WhyPortAyli"
import HowPortAyliWork from "./HowPortAyliWork"
import Feedbacks from "./Feedbacks"
import Contact from "./Contact"
import Footer from "./Footer"

export default function LandingPage({ setActive, setSim }) {
  React.useEffect(()=>{
    document.title = "PORTAYLI - Accueil"
  },[])
  return (
    <>
      <Hero setActive={setActive} setSim={setSim} />
      <Offers />
      <Advantages />
      <Tarif />
      <WhyPortAyli />
      <HowPortAyliWork />
      <Feedbacks />
      <Contact />
      <Footer />
    </>
  )
}

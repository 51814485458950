import React from "react"
import { TimeLine } from "./Icons"

export default function Tarif() {
  return (
    <div
      id="pourquoi ayli ?"
      className="mt-[10rem] flex flex-col items-center gap-[6rem]  px-8 lg:mt-[15rem] lg:gap-[10rem]"
    >
      <h1 className="w-[20rem] text-center text-[1.7rem] font-semibold text-primary md:w-[25rem] lg:w-full lg:text-[2rem]">
        Pourquoi Choisir PORTAYLI ?
      </h1>
      <TimeLine className="px-4 md:relative md:w-[40rem] md:px-0 lg:w-[50rem]" />
    </div>
  )
}

import React from "react"
import heroImage from "../assets/hero.png"
import { CheckMarkIcon, LineIcon } from "./Icons"
import ButtonNavItem from "./ButtonNavItem"
import { Link } from "react-router-dom"

export default function Hero({ setActive, setSim }) {
  return (
    <section
      id="hero"
      className="items-center justify-between px-8 pt-[8rem] lg:flex lg:pt-[15rem] 2xl:justify-between"
    >
      <div className="flex flex-col items-center gap-10 lg:items-start">
        <div className="flex flex-col items-center gap-2 lg:items-start">
          <h1 className="px-8 text-center text-[2.5rem] font-semibold text-primary md:px-0 lg:w-[30rem] lg:text-left">
            Tarif fixe et transparent
            <span className="text-active"> 400€/mois</span>
          </h1>
          <span className="text-md relative text-lg font-light text-primary">
            Notre devise:{" "}
            <span className="relative">
              Zéro Frais cachés
              <LineIcon className="absolute bottom-[-5.2rem] right-0 h-[10rem] w-[10rem]" />
            </span>
          </span>
        </div>

        <h2 className="text-center text-3xl font-light text-primary md:text-[2rem]">
          Payez moins, gagnez plus 
        </h2>
        <div className="flex flex-col gap-5">
          <div className="lg:text-md flex items-center gap-4 text-sm font-light text-primary lg:w-[35rem]">
            <CheckMarkIcon className="h-8 w-8" />
            <span className="w-full">
              Finies les difficultés administratives, laissez-vous porter vers
              le succès en toute transparence
            </span>
          </div>
          <div className="lg:text-md flex items-center gap-4 text-sm font-light text-primary lg:w-[35rem]">
            <CheckMarkIcon className="h-8 w-8" />
            <span className="w-full">
              Profitez des avantages du salariat tout en conservant votre
              liberté d'indépendant
            </span>
          </div>
        </div>
        <div className="mt-8 flex w-full flex-col items-center gap-5 lg:flex-row lg:gap-4">
          <Link
            to="/Simulateur"
            className="animation-button  w-[70%] cursor-pointer rounded-full bg-horizontalGradient py-5 text-center font-normal capitalize text-white hover:scale-[0.95] sm:w-72 lg:w-56"
            onClick={() => {
              document.title = "PORTAYLI - Simulateur"
              setActive("none")
            }}
          >
            Simulez votre revenu
          </Link>
          <ButtonNavItem
            idSection="contact"
            nameNavItem="contactez-nous"
            className="animation-button  w-[70%] cursor-pointer rounded-full border border-active bg-white  py-5 text-center font-normal capitalize text-active hover:scale-[0.95] sm:w-72 lg:w-56"
            offset={-378}
            onClick={() => {
              document.title = "PORTAYLI - Contact"
              setActive(1)
            }}
          />
        </div>
      </div>
      <img
        src={heroImage}
        alt="Hero"
        className="hero-animation hidden w-[25rem] lg:block 2xl:w-auto"
      />
    </section>
  )
}

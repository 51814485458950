import React from "react"
import { Route, Routes } from "react-router-dom"
import "./App.css"
import NavBar from "./components/NavBar"
import LandingPage from "./components/LandingPage"
import Simulateur from "./components/Simulateur"
import { useLocation } from "react-router-dom"

function App() {
  const location = useLocation().pathname.toLowerCase()
  const [active, setActive] = React.useState(
    location === "/simulateur" ? "none" : 0
  )
  return (
    <>
      <NavBar active={active} setActive={setActive} location={location} />
      <Routes>
        <Route path="/" element={<LandingPage setActive={setActive} />} />
        <Route path="/Simulateur" element={<Simulateur />} />
      </Routes>
    </>
  )
}

export default App
 
import React from "react"
import { ArrowFly, ExclamationIcon, SendIcon } from "./Icons"
import { isValidEmail, isValidName, isValidPhone } from "../helpers"
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function Contact() {
  const form = React.useRef()
  const [send, setSend] = React.useState(false)
  const [firstName, setFirstName] = React.useState("")
  const [errorFirstName, setErrorFirstName] = React.useState("")

  const [lastName, setLastName] = React.useState("")
  const [errorLastName, setErrorLastName] = React.useState("")

  const [email, setEmail] = React.useState("")
  const [errorEmail, setErrorEmail] = React.useState("")

  const [phone, setPhone] = React.useState("")
  const [errorPhone, setErrorPhone] = React.useState("")

  const [message, setMessage] = React.useState("")
  return (
    <>
      <ToastContainer autoClose={2000} />
      <section
        className="mt-[12rem] flex flex-col items-center justify-center gap-16 px-8 lg:mt-[25rem]  lg:flex-row lg:items-start lg:justify-around lg:gap-[4.5rem] 2xl:gap-[20rem]"
        id="contact"
      >
        <span className="relative w-full   text-center text-[1.7rem] font-semibold text-primary lg:bottom-0 lg:w-[20rem] lg:text-[3.5rem]">
          Contactez Nous
        </span>
        <form
          ref={form}
          className="flex w-full flex-col gap-12 lg:relative lg:w-[40rem]"
        >
          <ArrowFly className="absolute right-0 top-[-18rem] hidden h-[17rem] w-[17rem] lg:block" />
          <div className="flex w-full flex-col items-start gap-12 lg:flex-row lg:gap-4">
            <div className="flex w-full flex-col gap-1.5">
              <label className="text-sm text-primary">Prénom</label>
              <input
                type="text"
                name="user_lastName"
                value={lastName}
                className={`rounded-md border bg-grayLight p-4 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none ${
                  errorLastName.length
                    ? "border-redError "
                    : "border-transparent"
                }`}
                placeholder="Entrez votre prénom"
                onChange={(e) => {
                  setLastName(e.target.value)
                  setErrorLastName("")
                }}
              />
              {errorLastName.length ? (
                <div className="flex items-center gap-1.5 text-sm font-medium text-redError">
                  <ExclamationIcon className="h-4 w-4 fill-redError" />
                  <span>{errorLastName}</span>
                </div>
              ) : null}
            </div>
            <div className="flex w-full flex-col gap-1.5">
              <label className="text-sm text-primary">Nom</label>
              <input
                type="text"
                name="user_firstName"
                value={firstName}
                className={`${
                  errorFirstName.length
                    ? "border-redError"
                    : "border-transparent"
                } rounded-md border  bg-grayLight p-4 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none`}
                placeholder="Entrez votre nom"
                onChange={(e) => {
                  setFirstName(e.target.value)
                  setErrorFirstName("")
                }}
              />
              {errorFirstName.length ? (
                <div className="flex items-center gap-1.5 text-sm font-medium text-redError">
                  <ExclamationIcon className="h-4 w-4 fill-redError" />
                  <span>{errorFirstName}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex w-full flex-col items-start gap-12 lg:flex-row lg:gap-4">
            <div className="flex w-full flex-col gap-1.5">
              <label className="text-sm text-primary">Email</label>
              <input
                type="text"
                name="user_email"
                value={email}
                className={`${
                  errorEmail.length ? "border-redError" : "border-transparent"
                } rounded-md border bg-grayLight p-4 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none`}
                placeholder="Entrez votre email"
                onChange={(e) => {
                  setEmail(e.target.value)
                  setErrorEmail("")
                }}
              />
              {errorEmail.length ? (
                <div className="flex items-center gap-1.5 text-sm font-medium text-redError">
                  <ExclamationIcon className="h-4 w-4 fill-redError" />
                  <span>{errorEmail}</span>
                </div>
              ) : null}
            </div>
            <div className="flex w-full flex-col gap-1.5">
              <label className="text-sm text-primary">Téléphone</label>
              <input
                type="text"
                name="user_phone"
                value={phone}
                className={`${
                  errorPhone.length ? "border-redError" : "border-transparent"
                } rounded-md border bg-grayLight p-4 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none`}
                placeholder="Entrez votre téléphone"
                onChange={(e) => {
                  setPhone(e.target.value)
                  setErrorPhone("")
                }}
              />
              {errorPhone.length ? (
                <div className="flex items-center gap-1.5 text-sm font-medium text-redError">
                  <ExclamationIcon className="h-4 w-4 fill-redError" />
                  <span>{errorPhone}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1.5">
            <label className="text-sm text-primary">Message</label>
            <textarea
              type="text"
              name="message"
              className={`h-[11rem] rounded-md bg-grayLight p-3 text-sm placeholder:text-xs placeholder:text-[#B2B2B1] focus:outline-none`}
              placeholder="Entrez votre message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className={`animation-button flex w-48 items-center justify-center hover:scale-[0.95] ${
                send ? "gap-2" : "gap-6"
              } rounded-md bg-horizontalGradient p-3 font-medium text-white`}
              onClick={(e) => {
                e.preventDefault()
                let error = false
                const lastNameError = isValidName(lastName)
                const firstNameError = isValidName(firstName)
                const emailError = isValidEmail(email)
                const phoneError = isValidPhone(phone)

                if (lastNameError.length) {
                  error = true
                  setErrorLastName(lastNameError)
                }
                if (firstNameError.length) {
                  error = true
                  setErrorFirstName(firstNameError)
                }

                if (emailError.length) {
                  error = true
                  setErrorEmail(emailError)
                }

                if (phoneError.length) {
                  error = true
                  setErrorPhone(phoneError)
                }

                if (!error) {
                  setSend(true)
                  emailjs
                    .sendForm(
                      "service_5u5muwn",
                      "template_5xbb8wc",
                      form.current,
                      "89VIyJt-ULy7NVn07"
                    )
                    .then(
                      (result) => {
                        console.log(result)
                        setSend(false)
                        setLastName("")
                        setFirstName("")
                        setEmail("")
                        setPhone("")
                        setMessage("")
                        toast.success("Votre message envoyé avec succès.", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        })
                      },
                      (error) => {
                        console.log(error)
                        setSend(false)
                        setLastName("")
                        setFirstName("")
                        setEmail("")
                        setPhone("")
                        setMessage("")
                        toast.error("Votre message n'est pas envoyé.", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        })
                      }
                    )
                }
              }}
              {...(send ? { disabled: true } : {})}
            >
              {send ? (
                <div
                  className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
              <span>Envoyer</span>
              {send ? null : <SendIcon className="h-5 w-5 fill-white" />}
            </button>
          </div>
        </form>
      </section>
    </>
  )
}

import React from "react"
import { FormContext } from "./Simulateur"

export default function Button({ setOpenModal }) {
  const FormContextContent = React.useContext(FormContext)
  let collectedData = {}
  return (
    <button
      type="submit"
      className="mt-10 flex w-full items-center justify-center rounded-md bg-horizontalGradient p-3 font-medium text-white lg:w-60"
      onClick={(e) => {
        e.preventDefault()
        let error = false

        FormContextContent.forEach((input) => {
          try {
            input.required?.forEach((func) => {
              if (input.dropDown) func(input.value.value)
              else if (input.name === "tjm") func(input.value, 250)
              else if (input.name === "nombreDeJoursParMois")
                func(input.value, 1)
              else func(input.value)
              collectedData[input.name] = input.value
            })
          } catch (e) {
            error = true
            collectedData = {}
            input.setErrorMessage(e.message)
          }
        })

        if (!error) {
          setOpenModal(true)
          window.scrollTo(0,0)
        }
      }}
    >
      <span>Calculez vos revenus</span>
    </button>
  )
}

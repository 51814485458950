import React from "react"
import { EmailIcon, LinkedInIcon, LocationIcon, PhoneIcon } from "./Icons"

export default function Footer() {
  return (
    <footer className="mt-[12rem] flex flex-col items-center gap-24 bg-primary p-8 lg:gap-20">
      <div className="flex flex-col gap-12 lg:w-full lg:flex-row lg:items-center lg:justify-around lg:gap-0">
        <div className="flex w-full items-center gap-3 lg:w-auto">
          <LocationIcon className="w-4" />
          <span className="relative top-[.1rem] text-white">
            10 Rue de Penthièvre, 75008 Paris
          </span>
        </div>
        <div className="flex w-full items-center gap-3 lg:w-auto">
          <EmailIcon className="w-6" />
          <span className="text-white">contact@ayli-port.com</span>
        </div>
        <div className="flex w-full items-center gap-3 lg:w-auto">
          <PhoneIcon className="w-5" />
          <span className="text-white">07.57.81.45.59</span>
        </div>
        <a
          href="https://www.linkedin.com/company/portayli"
          className="group flex h-12 w-12 items-center justify-center rounded-full border border-white hover:bg-white"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className="w-4 fill-white group-hover:fill-primary" />
        </a>
      </div>
      <span className="text-white">
        © 2023 PORTAYLI – Tous droits réservés
      </span>
    </footer>
  )
}

import React from "react"
import { FormContext } from "./Simulateur"
import { separateWithSpace } from "../helpers"

export default function Total() {
  const FormContextContent = React.useContext(FormContext)

  let collectedData = {}
  for (let index = 0; index < FormContextContent.length; index++) {
    collectedData[FormContextContent[index].name] =
      FormContextContent[index].value
  }

  //values inserted by user
  const tjm = Number(collectedData.tjm)
  const fraisProfessionnels = Number(collectedData.fraisProfessionnels)
  //   const nombreDeChevaux = collectedData.nombreDeChevaux.value
  const coefficient = collectedData.nombreDeChevaux.coefficient
  const nombreDeJoursParMois = Number(collectedData.nombreDeJoursParMois)
  const distanceParcourue = Number(collectedData.distanceParcourue)

  // fix values

  const fraisDeGestion = 400.0
  const chargesSocialesSalariales = 1039.61
  // const fraisPro = 2330.0

  const indemnitesKilometriques =
    distanceParcourue * nombreDeJoursParMois * coefficient
  const masseSalariale =
    nombreDeJoursParMois * tjm -
    fraisDeGestion -
    fraisProfessionnels -
    indemnitesKilometriques
  const chargesSocialesPatronales = (31.4 / 100) * masseSalariale
  const brut = masseSalariale - chargesSocialesPatronales
  // const netAvantFraisPros = brut - chargesSocialesSalariales
  // const salaireNetAvantImpots = netAvantFraisPros + fraisPro
  const chiffreDaffairesHT = tjm * nombreDeJoursParMois
  const remboursementDesFraisProfessionnels =
    fraisProfessionnels + indemnitesKilometriques
  const salaireNet = brut - chargesSocialesSalariales
  const totalReverseAuConsultant =
    salaireNet + remboursementDesFraisProfessionnels
  const percentageReverseAuConsultant =
    (totalReverseAuConsultant / chiffreDaffairesHT) * 100
  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-active p-5 md:flex-row md:items-center md:gap-0">
          <span className="flex font-bold text-white">
            Total reversé au consultant
          </span>
          <span className="font-md text-white">
            {separateWithSpace(Number(totalReverseAuConsultant.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 px-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary"> Chiffre d'affaires HT</span>
          <span className="font-light text-active">
            {separateWithSpace(Number(chiffreDaffairesHT.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-darkGrayLight p-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Frais de gestion</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(fraisDeGestion.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 px-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Masse salariale</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(masseSalariale.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-darkGrayLight p-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Charges patronales</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(chargesSocialesPatronales.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 px-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Charges salariales</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(chargesSocialesSalariales.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-darkGrayLight p-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Salaire brut</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(brut.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 px-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">Salaire net</span>
          <span className="font-light text-primary">
            {separateWithSpace(Number(salaireNet.toFixed(2)))} €
          </span>
        </div>

        <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-darkGrayLight p-5 md:flex-row md:items-center md:gap-0">
          <span className="flex text-primary">
            Remboursement des frais professionnels
          </span>
          <span className="font-light text-primary">
            {separateWithSpace(
              Number(remboursementDesFraisProfessionnels.toFixed(2))
            )}{" "}
            €
          </span>
        </div>
        <div className="flex w-full flex-col justify-between gap-1 px-5 md:flex-row md:items-center md:gap-0">
          <span className="flex font-bold text-primary">
            Pourcentage reversé au consultant
          </span>
          <span className="text-primary">
            {separateWithSpace(
              Number(percentageReverseAuConsultant.toFixed(2))
            )}{" "}
            %
          </span>
        </div>
      </div>
    </>
  )
}

import React from "react"
import { FormContext } from "./Simulateur"
import { ExclamationIcon, ArrowDownIcon, ArrowUpIcon } from "./Icons"

export default function DropDown({ index, name, label, required }) {
  const FormContextContent = React.useContext(FormContext)

  const [clickButton, setClickButton] = React.useState(false)
  const [value, setValue] = React.useState({
    value: "Sélectionner une valeur",
    coefficient: 0,
  })

  const refDropDown = React.useRef(null)

  const [errorMessage, setErrorMessage] = React.useState("")

  const DropDownData = {
    dropDown: true,
    setErrorMessage: setErrorMessage,
    name: name,
    value: value,
    required: required,
  }

  const listDropDownItem = [
    {
      value: "3 CV",
      coefficient: 0.529,
    },
    {
      value: "4 CV",
      coefficient: 0.606,
    },

    {
      value: "5 CV",
      coefficient: 0.636,
    },
    {
      value: "6 CV",
      coefficient: 0.665,
    },
    {
      value: "7 + CV",
      coefficient: 0.697,
    },
  ]

  document.addEventListener("click", (e) => {
    if (
      refDropDown.current &&
      clickButton &&
      !refDropDown.current.contains(e.target)
    )
      setClickButton(false)
  })

  if (
    FormContextContent.find((element, indexElement) => indexElement === index)
  )
    FormContextContent[index] = DropDownData
  else FormContextContent.push(DropDownData)
  return (
    <div className="relative flex w-full flex-col gap-1.5">
      <label className="text-sm text-primary">{label}</label>
      <div className="relative" ref={refDropDown}>
        <button
          type="button"
          className={`flex items-center justify-between rounded-md border ${
            errorMessage.length ? "border-redError " : "border-transparent"
          } bg-darkGrayLight  w-full p-4 text-sm text-primary`}
          onClick={() => {
            setClickButton(!clickButton)
          }}
        >
          <span>{value.value}</span>
          {clickButton ? (
            <ArrowUpIcon className="h-3 w-3" />
          ) : (
            <ArrowDownIcon className="h-3 w-3" />
          )}
        </button>
        {clickButton ? (
          <div className="bg-darkGrayLight absolute z-[1] mt-3 flex max-h-[13rem] w-full flex-col items-start overflow-auto rounded-md text-sm text-primary shadow-lg  lg:max-h-[11rem]">
            {listDropDownItem.map((e, index) => (
              <DropDownItem
                key={index}
                value={e.value}
                coefficient={e.coefficient}
                setValue={setValue}
                setClickButton={setClickButton}
                setErrorMessage={setErrorMessage}
              />
            ))}
          </div>
        ) : null}
      </div>
      {errorMessage.length ? (
        <div className="absolute bottom-[-1.6rem] flex items-center gap-1.5 text-sm font-medium text-redError">
          <ExclamationIcon className="h-4 w-4 fill-redError" />
          <span>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  )
}

function DropDownItem({
  value,
  coefficient,
  setValue,
  setClickButton,
  setErrorMessage,
}) {
  return (
    <button
      type="button"
      className="hover:bg-[#F3F4F6] w-full p-4 text-left"
      onClick={() => {
        setErrorMessage("")
        setValue({
          value: value,
          coefficient: coefficient,
        })
        setClickButton(false)
      }}
    >
      {value}
    </button>
  )
}

import React from "react"
import {
  DocumentCheckIcon,
  EuroIcon,
  GiftCardIcon,
  GiftIcon,
  HealthIcon,
  InfoIcon,
  SupportIcon,
} from "./Icons"

export default function Offers() {
  return (
    <section className="flex flex-col items-center justify-center gap-[8rem] px-8 pt-[8rem] lg:pt-[12rem] ">
      <div className="flex w-full flex-col gap-20">
        <div className="flex flex-col items-center gap-20 lg:flex-row lg:items-start  lg:justify-around lg:gap-0">
          <div className="flex flex-col items-center gap-8">
            <GiftCardIcon className="w-[4.8rem] md:w-20 lg:w-24" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Des chèques cadeaux,culture et titres-restaurant
            </span>
          </div>
          <div className="flex flex-col items-center gap-8">
            <EuroIcon className="w-[3.3rem] md:w-[3.6rem] lg:w-16" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Frais professionnels déductibles pour optimiser vos finances
            </span>
          </div>
          <div className="flex flex-col items-center gap-8 lg:gap-4">
            <HealthIcon className="w-[4.3rem] md:w-[4.5rem] lg:w-[5rem]" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Mutuelle santé et prévoyance négociées par nos soins
            </span>
          </div>
        </div>

        <div className="flex flex-col items-center gap-20 lg:flex-row lg:items-start  lg:justify-around lg:gap-0">
          <div className="flex flex-col items-center gap-8">
            <InfoIcon className="w-[3.8rem] md:w-[4.2rem] lg:w-[4.3rem]" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Un bouquet de formations proposé par nos partenaires
            </span>
          </div>
          <div className="flex flex-col items-center gap-8 lg:gap-5">
            <DocumentCheckIcon className="w-16 md:w-[4.2rem] lg:w-[4.3rem]" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Épargne salariale PEE/PERCO pour nos salariés portés
            </span>
          </div>
          <div className="flex flex-col items-center gap-8  lg:gap-5">
            <GiftIcon className="w-[4.5rem] lg:w-[4.9rem]" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Une cagnotte mensuelle pour augmenter votre pouvoir d'achat
            </span>
          </div>
        </div>

        <div className="flex flex-col items-center gap-20 lg:flex-row lg:items-start  lg:justify-around lg:gap-0">
          <div className="flex flex-col items-center gap-8">
            <SupportIcon className="w-[4.9rem] md:w-[5.1rem] lg:w-[5.5rem]" />
            <span className="w-[19rem] text-center font-medium text-primary">
              Un conseiller dédié de 9h à 18h
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from "react"

import InputWithLabel from "./InputWithLabel"
import DropDown from "./DropDown"
import Button from "./Button"
import { isEmpty, isGreater, isNoChoose } from "../helpers"
import { Modal, ModalBody, ModalHeader } from "./Modal"
import Total from "./Total"
import { BgItemIcon } from "./Icons"

export const FormContext = React.createContext([])

export default function Simulateur() {
  const [openModal, setOpenModal] = React.useState(false)

  React.useEffect(() => {
    document.title = "PORTAYLI - Simulateur"
    window.scrollTo(0, 0)
  }, [])
  return (
    <FormContext.Provider value={[]}>
      <div className="mt-[8rem] flex w-full justify-center lg:mt-[9rem]">
        <main className="lg:gap-13  flex w-full  flex-col items-center gap-10 p-5 lg:ml-[6.5rem] lg:w-auto">
          <h1 className=" w-[20rem] text-center text-[1.7rem] font-semibold text-primary md:w-[25rem] lg:w-full lg:text-[2rem]">
            Simulateur PortAyli
          </h1>
          <div className="relative w-full lg:flex lg:items-center">
            <BgItemIcon className="absolute left-[-12rem] hidden lg:block" />
            <form className="flex w-full flex-col gap-12 rounded-xl bg-white p-8 shadow-3xl lg:relative lg:w-[45rem]">
              <div className="flex w-full flex-col items-start gap-12 lg:flex-row lg:gap-4">
                <InputWithLabel
                  index={0}
                  type="number"
                  name="tjm"
                  label="TJM"
                  placeholder="TJM"
                  symbol="€"
                  required={[isEmpty, isGreater]}
                  min={250}
                />
                <InputWithLabel
                  index={1}
                  type="number"
                  name="nombreDeJoursParMois"
                  label="Nombre de jours par mois (en jours)"
                  placeholder="nombre de jours par mois"
                  required={[isEmpty, isGreater]}
                  min={1}
                />
              </div>
              <div className="flex w-full flex-col items-start gap-12 lg:w-[50%] lg:flex-row lg:gap-4">
                <InputWithLabel
                  index={2}
                  type="number"
                  name="fraisProfessionnels"
                  label="Frais professionnels (non refacturables)"
                  placeholder="frais professionnels"
                  symbol="€"
                />
              </div>

              <div className="flex flex-col gap-5">
                <hr className="border-[#ebe6e6]" />
                <h2 className="text-center font-bold text-primary">
                  Indemnités Kilométriques
                </h2>
              </div>
              <div className="flex w-full flex-col items-start gap-12 lg:flex-row lg:gap-4">
                <DropDown
                  index={3}
                  name="nombreDeChevaux"
                  label="Nombre de chevaux"
                  required={[isNoChoose]}
                />
                <InputWithLabel
                  index={4}
                  type="number"
                  name="distanceParcourue"
                  label="Distance parcourue par jour"
                  placeholder="Distance parcourue"
                  symbol="km/j"
                  required={[isEmpty]}
                />
              </div>

              <div className="flex justify-end">
                <Button setOpenModal={setOpenModal} />
              </div>
            </form>
          </div>
          {openModal ? (
            <>
              <div className="fixed bottom-0 left-0 top-0 z-[4] h-screen w-full  bg-black/30  backdrop-blur-sm"></div>
              <Modal className="w-[90%] bg-white lg:w-[50rem]">
                <ModalHeader setOpen={setOpenModal}>Vos revenus</ModalHeader>
                <ModalBody className="mt-9 pb-4">
                  <Total />
                </ModalBody>
              </Modal>
            </>
          ) : null}
        </main>
      </div>
    </FormContext.Provider>
  )
}

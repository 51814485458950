import React from "react"
import { Link } from "react-scroll"

export default function ButtonNavItem({
  idSection,
  nameNavItem,
  className,
  offset,
  onClick,
  displaySim,
}) {
  return (
    <Link
      to={idSection}
      spy={true}
      smooth={displaySim ? false : true}
      offset={offset}
      duration={300}
      delay={100}
      className={className + " nav-item"}
      onClick={() => {
        onClick()
      }}
    >
      {nameNavItem}
    </Link>
  )
}
